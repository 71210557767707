import { api } from './axios';


interface LoginResponse {
    status: number;
    message: string;
    data: {
        token: string | null;
    };
}

export const login = async (email: string, password: string, role: string) => {
    try {
        const response = await api.post<LoginResponse>('auth', { email, password, role });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
};
