import { api } from "./axios";

export const fetchLocalities = async (): Promise<{ [state: string]: string[] }> => {
    try {
        const response = await api.get('localities', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });

        if (response.data.status === 200) {
            const localitiesData = response.data.data.localities;
            const formattedLocalities: { [state: string]: string[] } = {};

            localitiesData.forEach((state: any) => {
                formattedLocalities[state.name] = state.children.map((city: any) => city.name);
            });

            return formattedLocalities;
        } else {
            throw new Error('Failed to fetch localities');
        }
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch localities');
    }
};
