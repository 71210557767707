import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLock, FaUser, FaLocationArrow, FaPhoneAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { formatPhoneNumber, formatCNPJ } from '../components/functions/functions';
import axios from 'axios';
import { fetchLocalities } from '../services/localityService';
import { fetchBranchTypes } from '../services/branchTypesService';
import { registerCompany } from '../services/companyService';


const ContinueRegisterParceiroPage: React.FC = () => {

  const [loading, setLoading] = useState(false);

  const [company_name, setCompanyName] = useState('');
  const [cnpj, setCNPJ] = useState('');
  const [state_registration, setSateRegistration] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');


  const isLoggedIn = !!sessionStorage.getItem('authToken');

  const [localities, setLocalities] = useState<{ [state: string]: string[] }>({});
  const [branchTypes, setBranchTypes] = useState<{ id: string, name: string, visible: boolean }[]>([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedBranchType, setSelectedBranchType] = useState('');


  const navigate = useNavigate();



  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedPhone = formatPhoneNumber(value);
    setPhone(formattedPhone);
  };

  const handleCNPJChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedCNPJ = formatCNPJ(value);
    setCNPJ(formattedCNPJ);
  };
  

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }

    const fetchAndSetLocalities = async () => {
      try {
        const localitiesData = await fetchLocalities();
        setLocalities(localitiesData);
      } catch (error) {
        toast.error('Erro ao buscar localidades!');
      }
    };

    const fetchAndSetBranchTypes = async () => {
      try {
        const branchTypesData = await fetchBranchTypes();
        setBranchTypes(branchTypesData);
      } catch (error) {
        toast.error('Erro ao buscar localidades!');
      }
    }

    fetchAndSetLocalities();
    fetchAndSetBranchTypes();
  }, [isLoggedIn, navigate]);

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };


  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await registerCompany(
        company_name,
        address,
        phone.replace(/\D/g, ''), 
        selectedCity,
        selectedState,
        selectedBranchType,
        state_registration,
        cnpj.replace(/\D/g, '') 
      );

      if (response.status === 200) {
        toast.success('Empresa registrada com sucesso!');
        navigate('/companies');
        
      } else {
        const errorMessage = response.message || 'Ocorreu um erro durante o registro.';
        toast.error(errorMessage);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response?.data;

        if (errorResponse && typeof errorResponse === 'object' && 'errors' in errorResponse) {
          const errorMessages = Object.values(errorResponse.errors)
            .flat()
            .join(' ');

          toast.error(`Erro: ${errorMessages}`);
        } else {
          toast.error(error.response?.data?.message || 'Ocorreu um erro durante o registro.');
        }
      } else {
        toast.error('Ocorreu um erro durante o registro.');
      }
      console.error('Error during registration:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen">
      <div className="hidden md:flex items-center justify-center w-1/2 bg-mosaico">
        <img src='../mosaico_teste.png' alt='' className='w-1/2 h-auto' />
      </div>
      <div className="flex items-center justify-center w-full md:w-1/2 bg-white">
        <div className="p-6 rounded-lg w-full max-w-xl">
          <div className="flex justify-center mb-4">
            <img src="../logo.png" alt="Logo" className="h-20 w-auto" />
          </div>          
          <h2 className="text-2xl font-texta-heavy">Bem Vindo(a) ao MosaicoPlus</h2>
          <h2 className="text-xs font-texta-regular mb-4">Complete seu cadastro como parceiro para oferecer os melhores descontos para seus clientes...</h2>
          
          <form onSubmit={handleRegister}>
            <div className="mb-4">
                <div className="pr-2">
                    <label htmlFor="company_name" className="block text-sm font-texta-regular text-gray-700">
                    Nome da Empresa
                    </label>
                    <div className="relative">
                    <input
                        id="company_name"
                        type="text"
                        placeholder="Nome da Empresa"
                        value={company_name}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                        required
                    />
                    <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                </div>
            </div>
              

            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                    <label htmlFor="cnpj" className="block text-sm font-texta-regular text-gray-700">
                    CNPJ
                    </label>
                    <div className="relative">
                    <input
                        id="cnpj"
                        type="text"
                        placeholder="CNPJ"
                        value={cnpj}
                        onChange={handleCNPJChange}
                        className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                        required
                    />
                    <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                </div>

                <div className="w-1/2 pl-2">
                <label htmlFor="state_registration" className="block text-sm font-texta-regular text-gray-700">
                Inscriçao Estadual
              </label>
              <div className="relative">
                <input
                  id="state_registration"
                  type="text"
                  placeholder='Inscriçao Estadual'
                  value={state_registration}
                  onChange={(e) => setSateRegistration(e.target.value)}
                  className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                  required
                />
                <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
                </div>
              
            </div>

            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label htmlFor="state" className="block text-sm font-texta-regular text-gray-700">Estado</label>
                <select
                  id="state"
                  value={selectedState}
                  onChange={handleStateChange}
                  className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200"
                  required
                >
                  <option value="">Selecione um estado</option>
                  {Object.keys(localities).map((state) => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="city" className="block text-sm font-texta-regular text-gray-700">Cidade</label>
                <select
                  id="city"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200"
                  disabled={!selectedState}
                  required
                >
                  <option value="">Selecione uma cidade</option>
                  {selectedState && localities[selectedState]?.map((city) => (
                    <option key={city} value={city}>{city}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex mb-4">
              <div className='w-1/2 pr-2'>
                <label htmlFor="branch_type" className="block text-sm font-texta-regular text-gray-700">Tipo de Ramo</label>
                <select
                  id="branch_type"
                  value={selectedBranchType}
                  onChange={(e) => setSelectedBranchType(e.target.value)}
                  className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200"
                  required
                >
                  <option value="">Selecione um tipo de ramo</option>
                  {branchTypes.filter(branch => branch.visible).map((branchType) => (
                    <option key={branchType.id} value={branchType.id}>
                      {branchType.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="phone" className="block text-sm font-texta-regular text-gray-700">
                Telefone da Empresa
              </label>
              <div className="relative">
                <input
                  id="phone"
                  type="text"
                  placeholder='Telefone'
                  value={phone}
                  onChange={handlePhoneChange}
                  className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                  required
                />
                <FaPhoneAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
                </div>
          </div>
          <div className="mb-4">
              <label htmlFor="address" className="block text-sm font-texta-regular text-gray-700">Endereço da Empresa</label>
              <div className="relative">
                <input
                  id="address"
                  type="text"
                  placeholder="Endereço da Empresa"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                  required
                />
                <FaLocationArrow className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
          </div>
          
            <button
              type="submit"
              className="font-texta-heavy w-full bg-mosaico border border-mosaico text-white p-2 rounded-md shadow-sm hover:bg-white hover:text-mosaico hover:border-mosaico"
              disabled={loading}
            >
              {loading ? 'Carregando...' : 'Completar Registro'}
            </button>
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContinueRegisterParceiroPage;
