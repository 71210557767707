import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import LoginPage from './pages/LoginPage';
import AppBar from './components/AppBar';
import AdminPage from './pages/admin/AdminPage';
import './assets/styles/font.css';  
import RegisterPage from './pages/RegisterPage';
import ContinueRegisterParceiroPage from './pages/ContinueRegisterParceiroPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomePageCompanyPage from './pages/companies/HomePageCompany';
import AdminUsersPage from './pages/admin/users/AdminUsersPage';
import AdminUserDetailPage from './pages/admin/users/AdminUserDetailPage';
import AdminUserEditPage from './pages/admin/users/AdminUserEditPage';
import AdminCategoriesPage from './pages/admin/categories/AdminCategoriesPage';
import AdminUsersCreatePage from './pages/admin/users/AdminUsersCreatePage';
import AdminCategoriesCreatePage from './pages/admin/categories/AdminCategoriesCreatePage';

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <AppBar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin/users" element={<AdminUsersPage />} />
            <Route path="/admin/users/:userId" element={<AdminUserDetailPage />} />
            <Route path="/admin/users/edit/:userId" element={<AdminUserEditPage />} />
            <Route path="/admin/users/create" element={<AdminUsersCreatePage />} />
            <Route path="/admin/categories" element={<AdminCategoriesPage />} />
            <Route path="/admin/categories/create/:id" element={<AdminCategoriesCreatePage />} />
            <Route path="/admin/categories/create" element={<AdminCategoriesCreatePage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/register/parceiro" element={<ContinueRegisterParceiroPage />} />
            <Route path="/companies" element={<HomePageCompanyPage />} />
          </Routes>
        </main>
        <ToastContainer />
      </div>
    </Router>
  );
};

export default App;
