import React, { useEffect, useState } from 'react';
import { FaBookOpen, FaSearch, FaTrash, FaEdit, FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import Sidebar from '../../../components/Sidebar';
import { useNavigate } from 'react-router-dom';
import { Category } from '../../../interfaces/category_interface';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { BASE_URL } from '../../../config/config';
import { deleteCategory, fetchCategories, updateVisibility } from '../../../services/categoryService';
import axios from 'axios';

const AdminCategoriesPage: React.FC = () => {
  const [search, setSearch] = useState('');
  const [visibleFilter, setVisibleFilter] = useState<string>('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState<number | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCategories();
        if (response.status === 200) {
          setCategories(response.data.categories);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error('Failed to fetch categories.');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      const filtered = categories.filter(category => {
        const matchesSearch = category.name.toLowerCase().includes(search.toLowerCase());
        const matchesVisible = visibleFilter ? category.visible.toString() === visibleFilter : true;
        return matchesSearch && matchesVisible;
      });

      setFilteredCategories(filtered);
      setPageCount(Math.ceil(filtered.length / 15)); // Define o número de páginas com base no número filtrado de categorias
    };

    applyFilters();
  }, [categories, search, visibleFilter]);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
  };

  const handleCreate = () => {
    navigate('/admin/categories/create');
  };

  const handleDelete = (categoryId: number) => {
    setCategoryIdToDelete(categoryId);
    setModalVisible(true);
  };

  const confirmDelete = async () => {
    if (categoryIdToDelete !== null) {
      try {
        const response = await deleteCategory(categoryIdToDelete);
        if (response.status === 200) {
          toast.success(response.message);
          setModalVisible(false);
          // Atualize as listas de categorias removendo a categoria deletada.
          setCategories(categories.filter(category => category.id !== categoryIdToDelete));
          setFilteredCategories(filteredCategories.filter(category => category.id !== categoryIdToDelete));
        } else {
          toast.error(response.message || 'Failed to delete category.');
        }
      } catch (error) {
        toast.error('Failed to delete category.');
      }
    }
  };

  const handleViewImage = (imageUrl: string) => {
    setImageSrc(imageUrl);
    setImageModalVisible(true);
  };

  const handleEdit = (id: number) => {
    navigate(`/admin/categories/create/${id}`);
  };

  const handleToggleVisibility = async (id: number, currentVisibility: boolean) => {
    try {
      const newVisibility = !currentVisibility;
      await updateVisibility(id, newVisibility);
      // Atualize a lista de categorias após a alteração
      setCategories(categories.map(category => 
        category.id === id ? { ...category, visible: newVisibility } : category
      ));
      setFilteredCategories(filteredCategories.map(category => 
        category.id === id ? { ...category, visible: newVisibility } : category
      ));
      toast.success(`Category ${newVisibility ? 'activated' : 'deactivated'} successfully`);
    } catch (error) {
      // Garantir que o erro seja uma string ou mensagem
      const errorMessage = axios.isAxiosError(error) && error.response?.data?.message
        ? error.response.data.message
        : 'Failed to update visibility.';
      toast.error(errorMessage);
    }
  };
  

  return (
    <div className="flex h-screen bg-gray-100 font-texta-regular">
      <Sidebar />

      <div className="flex-grow p-8 flex-1 overflow-y-auto">
        {/* Navbar */}
        <nav className="bg-gray-100 px-6 py-4 flex justify-between items-center w-100">
          <div className="relative flex-grow">
            <input
              id="search"
              type="text"
              placeholder="Busque aqui..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
              required
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </nav>

        {/* Filtros */}
        <div className="p-4 bg-gray-100">
          <div className="flex flex-wrap gap-4">
            <div className="flex-1">
              <label htmlFor="visibleFilter" className="block text-sm font-medium text-gray-700">Filtrar por Visibilidade</label>
              <select
                id="visibleFilter"
                value={visibleFilter}
                onChange={(e) => setVisibleFilter(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Todos</option>
                <option value="true">Visível</option>
                <option value="false">Não Visível</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-grow p-6 bg-gray-100">
          <div className="flex justify-start mb-4">
            <button type="submit" onClick={handleCreate} className='bg-mosaico text-white px-4 py-2 rounded-md flex items-center space-x-2'>
              <FaBookOpen size={16} />
              <span>Nova Categoria</span> 
            </button>
          </div>

          <h2 className="text-2xl font-texta-regular text-gray-700 mb-6">Categorias</h2>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 text-center">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Slug</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Caption</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Ativo</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Ações</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredCategories.slice(currentPage * 15, (currentPage + 1) * 15).map(category => (
                  <tr key={category.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{category.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{category.slug}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        onClick={() => handleViewImage(`${BASE_URL}${category.caption}`)}
                        className="text-mosaico hover:underline"
                      >
                        Ver Imagem
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {category.visible ? 'Sim' : 'Não'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-center">
                      <div className="flex items-center justify-center space-x-2">
                        {category.visible ? (
                          <FaEye
                            className="text-green-500 cursor-pointer"
                            title="Desativar"
                            onClick={() => handleToggleVisibility(category.id, category.visible)}
                          />
                        ) : (
                          <FaEyeSlash
                            className="text-gray-500 cursor-pointer"
                            title="Ativar"
                            onClick={() => handleToggleVisibility(category.id, category.visible)}
                          />
                        )}
                        <button onClick={() => handleDelete(category.id)} className="text-red-500 hover:text-red-700">
                          <FaTrash />
                        </button>
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-center mt-4">
          <ReactPaginate
              previousLabel={'←'}
              nextLabel={'→'}
              breakLabel={'...'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'flex space-x-1'}
              pageClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              pageLinkClassName={'text-sm'}
              previousClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              previousLinkClassName={'text-sm '}
              nextClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              nextLinkClassName={'text-sm '}
              breakClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded'}
              breakLinkClassName={'text-sm'}
              activeClassName={'bg-mosaico text-white'}
            />
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        {modalVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Confirmar Exclusão</h3>
              <p className="text-sm text-gray-600 mb-4">Tem certeza de que deseja excluir esta categoria?</p>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setModalVisible(false)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={confirmDelete}
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Image Modal */}
        {imageModalVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <img src={imageSrc} alt="Category" className="w-full h-auto" />
              <button
                type="button"
                onClick={() => setImageModalVisible(false)}
                className="absolute top-2 right-2 text-gray-500"
              >
                <FaTimes size={24} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminCategoriesPage;
